<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Bulan"
    allow-clear
    show-search
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (searchText = null)"
    @select="() => (searchText = null)"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
    :disabled="disabled"
    style="width:300px">
    <a-select-option v-for="d in results" :key="d.value" :label="d.value">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.value)"></span>
      <!--eslint-enable-->
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    mode: {
      default: null,
      type: String,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const results = ref([])
    const searchText = ref(null)
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true

      apiClient
        .get('/api/filter/rkap/month', {
          params: pickBy({
            q: searchText.value,
          }),
        })
        .then(({ data }) => {
          results.value = data
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = debounce(value => {
      searchText.value = value
      fetchData()
    }, 300)

    const highlight = value => {
      return value.replace(new RegExp(searchText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    return {
      results,
      valueState: useVModel(props, 'value'),
      searchText,
      highlight,
      onSearch,
      loading,
    }
  },
}
</script>
