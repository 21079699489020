<template>
    <AModal
        :visible="visible"
        :title="(form.id != null ? 'Edit' : 'Tambah') + ` RKAP`"
        width="40%"
        @cancel="handleModalCancel"
        @ok="handleOkModal">
            <template #footer>
            <AButton
                key="back"
                @click="handleModalCancel">Batal</AButton>
            <AButton
                key="submit"
                type="primary"
                :loading="form.busy"
                @click="handleOkModal">{{
                    form.id != null ? 'Perbarui' : 'Simpan'
                }}</AButton>
        </template>

        <AForm
            class="myform"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true"
            :model="form"
            ref="formRef"
            :rules="state.rules"
            @keydown="form.onKeydown($event)">
            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Distributor"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributorCurah
                            v-model:value="form.vendor_id"
                            :mode="null"
                            style="width: 100%"/>
                    </AFormItem>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Tahun"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="years"
                        has-feedback>
                        <FilterRkapYear
                            v-model:value="form.years"
                            style="width: 100%"/>
                    </AFormItem>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Bulan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="month"
                        has-feedback>
                        <FilterRkapMonth
                            v-model:value="form.month"
                            style="width: 100%"/>
                    </AFormItem>
                </a-col>
            </a-row>
            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Nilai RKAP"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="rkap_value"
                        has-feedback>
                        <AInputNumber
                            placeholder="nilai rkap"
                            type="number"
                            v-model:value="form.rkap_value"
                            style="width: 100%"/>
                    </AFormItem>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable />
            </ASpace>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import Form from 'vform'
import FilterRkapYear from '@/components/filter/FilterRkapYear'
import FilterRkapMonth from '@/components/filter/FilterRkapMonth'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'

export default defineComponent({
    components: {
        FilterRkapYear,
        FilterRkapMonth,
        FilterDistributorCurah,
    },
    props: {
        visible: [Boolean],
        item: {
            type: [Object],
            default: () => {},
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref(null)
        const formRef = ref('');

        const state = reactive({
            rules: {
                vendor_id: [
                    {
                        required: true,
                        message: 'Distributor tidak boleh kosong',
                    },
                ],
                month: [
                    {
                        required: true,
                        message: 'Bulan tidak boleh kosong',
                    },
                ],
                years: [
                    {
                        required: true,
                        message: 'Tahun tidak boleh kosong',
                    },
                ],
                rkap_value: [
                    {
                        required: true,
                        message: 'Nilai RKAP tidak boleh kosong',
                    },
                ],
            },
        })

        const form = reactive(new Form({
            id: null,
            vendor_id: null,
            month: null,
            years: null,
            rkap_value: null,
        }))

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        };

        const handleOkModal = async () => {

            await formRef.value.validate()

            if (form.id != null) {
                form.put(`/api/rkap/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil diperbarui')

                        form.reset()
                        handleModalCancel()

                        emit('success', data)
                    })
            } else {
                form.post('/api/rkap')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil ditambahkan')

                        form.reset()
                        handleModalCancel()

                        emit('success', data)
                    })
            }
        };

        // handle vue
        onMounted(() => {
            if (props.item) {
                form.id = props.item.id
                form.vendor_id = props.item.vendor_id
                form.month = props.item.month
                form.years = props.item.years
                form.rkap_value = props.item.rkap_value
            }
        })

        return {
            form,
            formRef,
            state,
            handleModalCancel,
            handleOkModal,
        }
    },
})
</script>